<template>
  <div class="home">
    <ContentVue />
    <ServiceCardVue />
    <WhyUs />
    
  </div>
  
</template>

<script>

import ServiceCardVue from '@/components/ServiceCardVue.vue'
import ContentVue from '@/components/ContentVue.vue';
import WhyUs from '@/components/WhyUs.vue'



// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
    ServiceCardVue,
    ContentVue,
    WhyUs,
    
    
}
}
</script>
