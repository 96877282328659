<template>
  <header :class="{ 'scrolled-nav' : scrolledNav}">
    <nav>
      <div class="branding">
        <img src="../assets/img/logo/Hanrahan-Logo-white-on-clear-background.gif" alt="Logo">
      </div>

      <ul v-show="!mobile" class="navigation">
        <li><router-link class="link" :to="{name: 'home'}">Home</router-link></li>
        <li><router-link class="link" :to="{name: 'services'}">Services</router-link></li>
        <li><router-link class="link" :to="{name: 'about'}">About</router-link></li>
        <li><router-link class="link" :to="{name: 'machines'}">Machines</router-link></li>
        <li><router-link class="link" :to="{name: 'contact'}">Contact</router-link></li>
        
      </ul>

      <div class="icon">
        <font-awesome-icon :icon="['fas', 'bars']" style="color: #ffffff;"  @click="toggleMobileNav" v-show="mobile" :class="{'icon-active' : mobileNav}" class="bars"/>
      </div>

      <transition name="mobile-nav" class="">
        <ul v-show="mobileNav" class="dropDownNav">
          <li><router-link @click="toggleMobileNav" class="link" :to="{name: 'home'}">Home</router-link></li>
        <li><router-link @click="toggleMobileNav" class="link" :to="{name: 'services'}">Services</router-link></li>
        <li><router-link @click="toggleMobileNav" class="link" :to="{name: 'about'}">About</router-link></li>
        <li><router-link @click="toggleMobileNav" class="link" :to="{name: 'machines'}">Machines</router-link></li>
        <li><router-link @click="toggleMobileNav" class="link" :to="{name: 'contact'}">Contact</router-link></li>
        
        
      </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
 name: 'NavBar',
 data() {
  return {
    scrolledNav: null,
    mobile: null,
    mobileNav: null, 
    windowWidth: null,
  };
 }, 
 created() {
  window.addEventListener('resize', this.checkScreen);
  this.checkScreen();
 },
 mounted() {
  window.addEventListener("scroll", this.updateScroll)
 },
methods: {
  toggleMobileNav() {
    this.mobileNav = !this.mobileNav;
  }, 

  updateScroll(){
    const scrollPosition = window.scrollY;
    if (scrollPosition > 50) {
      this.scrolledNav = true;
      return;
    }
    this.scrolledNav = false;
  },

  checkScreen() {
    this.windowWidth = window.innerWidth;
    if(this.windowWidth <= 750) {
      this.mobile = true;
      return;
    }
    this.mobile = false;
    this.mobileNav = false;
    return;
  }
}
};
</script>

<style lang="scss" scoped>
header {
  background-color: rgba(25, 120, 197, 0.8);
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: .5s ease all;
  color: #fff;


nav {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  transition: .5s ease all;
  margin: 0 auto;

  @media(min-width: 80%) {
    max-width: 80%;
  }

  ul, .link {
    font-weight: 700;
    color: #fff;
    list-style: none;
    text-decoration: none;
  }

  li {
    text-transform: uppercase;
    padding: 16px;
    margin-left: 16px;
  }

  .link {
    font-size: 14px;
    transition: .5s ease all;
    padding-bottom: 4px;
    border-bottom: 1px solid transparent;

    &:hover {
      color: #00afea;
      border-color: #00afea;
    }
  }

  .branding {
    display: flex;
    align-items: center;
  }

  img {
    width: 200px;
    height:100%;
    transition: .5s ease all;
  }

  .navigation {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  .icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 24px;
    height: 100%;

    .bars {
      cursor: pointer;
      font-size: 24px;
      transition: 0.8s ease all;
    }
  }

  .icon-active {
    transform: rotate(180deg);
  }

  .dropDownNav {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    max-width: 250px;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 0;

    li {
      margin-left: 0;
      .link {
        color: #000;
      }
    }
  }

  .mobile-nav-enter-active, .mobile-nav-leave-active {
    transition: 1s ease all;
  }

  .mobile-nav-enter-from, .mobile-nav-leave-to {
    transform: translateX(-250px)
  }

  .mobile-nav-enter-to{
    transform: translateX(0)
  }
  }
}

.scrolled-nav { 
background-color: rgba(25, 120, 197, 0.9);
box-shadow: 0 4px 6px -1px rgba(0,0,0, 0.1), 0 2px 4px -1px(0,0,0, 0.06);

nav {
  padding: 8px 0;

  .branding {
    img { 
      width: 140px;
      height:100%;
      box-shadow: 0 4px 6px -1px rgba(0,0,0, 0.1), 0 2px 4px -1px(0,0,0, 0.06);
    }
  }
}
}

</style>