<template>
  <section class="pb-8">
  <h2 class="text-3xl lg:text-6xl font-bold text-gray-800 uppercase my-20 md:pb-10">Why Choose Hanrahan Access?</h2>
  <div class="whyContainer flex flex-wrap">
    <div class="md:w-1/2 mb-10 md:pr-10 py-8 bg-gradient-to-r from-blue-200 to-white">
    <div class="flex justify-center">
      <font-awesome-icon :icon="['far', 'clock']" beat size="2xl" class="text-blue-700" />
      <h3 class="text-3xl font-bold text-center text-gray-800 uppercase pb-6 px-4">Save Time</h3>
      <font-awesome-icon :icon="['far', 'clock']" beat size="2xl" class="text-blue-700" />
    </div>
    
    <p class="md:text-justify text-lg px-12">Eliminate the need for expensive scaffolding or high risk rope access! The time it takes to deploy one of our underbridge platforms ranges from 3 - 7 minutes depending of the size of the machine and the location of the workface.</p>
  </div>
  <div class="hidden md:block md:w-1/2 mb-10 pr-10">
    <p class="text-center text-white">white text</p>
  </div>
  <div class="hidden md:block md:w-1/2 mb-10 pr-10">
    <p class="text-white">white text</p>
  </div>
  <div class="md:w-1/2 mb-10 md:pr-10 py-8 bg-gradient-to-l from-blue-200 to-white">
  <div class="flex justify-center">
    <font-awesome-icon :icon="['fas', 'helmet-safety']" beat size="2xl" class="text-blue-700"/>
    <h3 class="text-2xl md:text-3xl font-bold text-center text-gray-800 uppercase pb-6 px-4">Enhance Safety</h3>
    <font-awesome-icon :icon="['fas', 'helmet-safety']" beat size="2xl" class="text-blue-700"/>
  </div>
    <p class="md:text-right text-lg px-12">Our Under bridge platforms enable Clients to access the hardest to reach locations on any of their highway and marine assets and infrastructure networks, by the safest means possible. Eliminate risks associated with working at height and utilise the built in safety features found on all of our access platforms.</p>
  </div>
  
  <div class="md:w-1/2 mb-10 md:pr-10 py-8 bg-gradient-to-r from-blue-200 to-white">
  <div class="flex justify-center">
    <font-awesome-icon :icon="['fas', 'sterling-sign']" beat size="2xl" class="text-blue-700"/>
    <h3 class="text-3xl font-bold text-center text-gray-800 uppercase pb-6 px-4">Save Money</h3>
    <font-awesome-icon :icon="['fas', 'sterling-sign']" beat size="2xl" class="text-blue-700"/>
  </div>
    
    <p class="md:text-justify text-lg px-12">We provide a cost effective Operator Hire service which is a fraction of the cost of other means of providing safe access. Call us today to find out how we can help you find the best and most cost effective solution to suit your budget.</p>
  </div>
  
  </div>
</section>
</template>

<script>
export default {

}
</script>

<style>

</style>