<template>
  <footer id="contact" class="border border-t-4 border-t-blue-700 bg-blue-100">
    <div class="mx-auto">

<footer class="p-4 shadow flex items-center justify-between md:p-6 border-t-2 border-gray-200">
  <span class="text-xs text-gray-900 sm:text-center dark:text-gray-400">©2024 <a href="#" class="hover:underline" target="_blank">Hanrahan Access Platform Ltd™</a>. All Rights Reserved.
  </span>
  <div>
    <ul class="flex flex-wrap items-center mt-3 sm:mt-0">
    <li>
      <a href="/" class="mr-4 text-sm text-gray-900 hover:underline md:mr-6 dark:text-gray-300">Home</a>
    </li>
    <li>
      <a href="/services" class="mr-4 text-sm text-gray-900 hover:underline md:mr-6 dark:text-gray-400">Services</a>
    </li>
    <li>
      <a href="/about" class="text-sm text-gray-900 hover:underline dark:text-gray-400 mr-6">About</a>
    </li>
    <li>
      <a href="/machines" class="text-sm text-gray-900 hover:underline dark:text-gray-400 mr-6">Machines</a>
    </li>
    <li>
      <a href="/contact" class="text-sm text-gray-900 hover:underline dark:text-gray-400">Contact</a>
    </li>
  </ul>
  </div>
  
</footer>
</div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>