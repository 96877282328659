<template>

	<!-- new section -->

<section class="container mx-auto px-8 py-10 lg:py-20">

  <h2 class="block antialiased tracking-normal text-4xl md:text-6xl font-bold leading-[1.3] !leading-snug ">OUR SERVICES</h2>
  <!-- <p class="block antialiased font-sans text-xl font-normal leading-relaxed text-inherit mt-2 w-full font-normal !text-gray-500">Read about our latest achievements and milestones.</p> -->

  <div class="mt-10 grid grid-cols-1 gap-10 lg:grid-cols-3">
    <a href="/services#hire">
    <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"><img src="../assets/img/man1.jpg" alt="bg" class="absolute inset-0 h-full w-full object-cover object-center" />
      <div class="absolute inset-0 bg-black/30"></div>
      <div class="p-6 relative flex flex-col justify-end">
        <h4 class="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-white">Access Platform Hire</h4>
        <p class="block antialiased font-sans text-lg font-light leading-relaxed text-white my-2 font-normal">We are the UK's leading provider of Specialist Underbridge Access Platforms for the inspection and maintenance of highway and marine structures.</p>
      </div>
    </div>

    </a>
    <a href="/services#loler">
    <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"><img src="../assets/img/LOLER.jpg" alt="bg" class="absolute inset-0 h-full w-full object-cover object-center" />
      <div class="absolute inset-0 bg-black/30"></div>
      <div class="p-6 relative flex flex-col justify-end">
        <h4 class="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-white">LOLER Inspections</h4>
        <p class="block antialiased font-sans text-lg font-light leading-relaxed text-white my-2 font-normal">We provide a Nationwide LOLER Inspection and Certification Service carried out by our Team of Professionally Qualified Engineers.</p>
      </div>
    </div>
    </a>

    <a href="/services#maintain">
    <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"><img src="../assets/img/maintain.jpg" alt="bg" class="absolute inset-0 h-full w-full object-cover object-center" />
      <div class="absolute inset-0 bg-black/30"></div>
      <div class="p-6 relative flex flex-col justify-end">
        <h4 class="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-white">Maintainance, Servicing & Repairs</h4>
        <p class="block antialiased font-sans text-lg font-light leading-relaxed text-white my-2 font-normal">24/7 Maintenance, Servicing and Repairs to a wide range of Access Platforms.</p>
      </div>
    </div>
    </a>

    <a href="/services#welding">
    <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"><img src="../assets/img/WE3.jpg" alt="bg" class="absolute inset-0 h-full w-full object-cover object-center" />
      <div class="absolute inset-0 bg-black/30"></div>
      <div class="p-6 relative flex flex-col justify-end">
        <h4 class="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-white">Fabrication</h4>
        <p class="block antialiased font-sans text-lg font-light leading-relaxed text-white my-2 font-normal">Manufacture of cages and bespoke items in our fully equipped workshop in Luton</p>
      </div>
    </div>
    </a>

    <a href="/contact">
    <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"><img src="../assets/img/Sale.jpg" alt="bg" class="absolute inset-0 h-full w-full object-cover object-center" />
      <div class="absolute inset-0 bg-black/30"></div>
      <div class="p-6 relative flex flex-col justify-end">
        <h4 class="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-white">Machine Sales</h4>
        <p class="block antialiased font-sans text-lg font-light leading-relaxed text-white my-2 font-normal">We also offer Access Platforms for sale. Get in touch to find out more.</p>
        
      </div>
    </div>
    </a>

    <a href="/contact">
    <div class="relative flex flex-col bg-clip-border rounded-xl bg-transparent text-gray-700 shadow-md relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"><img src="../assets/img/CarSide.jpg" alt="bg" class="absolute inset-0 h-full w-full object-cover object-center" />
      <div class="absolute inset-0 bg-black/30"></div>
      <div class="p-6 relative flex flex-col justify-end">
        <h4 class="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug text-white">Get In Touch</h4>
        <p class="block antialiased font-sans text-lg font-light leading-relaxed text-white my-2 font-normal">Can't see what you need? Get in touch and we will find the right platform solution for you</p>
      </div>
    </div>
    </a>

  </div>
</section>



</template>

<script>
export default {

}
</script>

<style>

</style>