<template>
  <!-- Section: Design Block -->

<section class="pt-24">
  <!-- Background image -->
  <div
    class="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] bg-[url('../assets/img/CarBack.jpg')] h-[500px]">
  </div>

  <div class="w-100 mx-auto px-6 sm:max-w-2xl md:max-w-3xl md:px-12 lg:max-w-5xl xl:max-w-7xl xl:px-32">
    <div class="text-center">
      <div
        class="block mb-12 rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:py-16 md:px-12 mt-[-170px] backdrop-blur-[30px]">
        <h1 class="mt-2 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl">
          What we can do <span class="text-primary">for you</span>
        </h1>
        <!-- <a class="mb-2 inline-block rounded bg-blue-200 px-12 pt-4 pb-3.5 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] md:mr-2 md:mb-0"
          data-te-ripple-init data-te-ripple-color="light" href="#!" role="button">Get started</a>
        <a class="inline-block rounded px-12 pt-4 pb-3.5 text-sm font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 dark:hover:bg-neutral-700 dark:hover:bg-opacity-40"
          data-te-ripple-init data-te-ripple-color="light" href="#!" role="button">Learn more</a> -->
      </div>
    </div>
  </div>
  <!-- Background image -->
</section>
<!-- Section: Design Block -->

<div class="container mx-auto md:px-6">
  <!-- Section: Design Block -->
  <section class="mb-32 pt-8 md:pt-32">

    <div id="hire" class="mb-16 flex flex-wrap">
      <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
        <div
          class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src="../assets/img/PM.jpg" class="w-full" alt="Louvre" />
          <a href="#!">
            <div
              class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
            </div>
          </a>
        </div>
      </div>

      <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
        <h3 class="mb-4 text-4xl font-bold">Underbridge and Hydraulic Access Platform Hire</h3>
        
        <div class="mx-4">
        <p class="mb-6 text-xl">
          We are the UK's leading provider of Specialist Underbridge Access Platforms for the inspection and maintenance of highway and marine structures
        </p>
        <p class="mb-6 text-xl">
          Underbridge units offer quick and safe access to most difficult places beneath bridge structures. These underbridge units excel in difficult to reach places, and as they are designed to reduce traffic disruption, they allow you to inspect bridge structures quickly and efficiently. The extendable boom arms allow you to work underneath the bridge structures and make it easier to reach places behind or between large structures.
        </p>
        <p class="mb-6 text-xl">
          These multifunctional units provide a cost-effective bridge inspection with flexible solution to identify bridge maintenance requirements and asses the effectiveness of treatments. They are ideal access units for bridge inspection, repair of bridges, harbour walls, quay walls, docks, retaining walls, and other areas where the best point-of-access is from above.
        </p>
        <p class="mb-6 text-xl">
          Our underbridge units can be deployed to use for under bridges or can be utilised as aerial work platforms.
        </p>
      </div>

        
      </div>
    </div>

    <div id="loler" class="mb-16 flex flex-wrap lg:flex-row-reverse">
      <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
        <div
          class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src="../assets/img/LOLER.jpg" class="w-full" alt="Loler Inspection" />
          <a href="#!">
            <div
              class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
            </div>
          </a>
        </div>
      </div>

      <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
        <h3 class="mb-4 text-3xl font-bold">LOLER Inspections and Certification</h3>
        
        <div class="mx-4">
        <p class="mb-6 text-xl">
          We provide a Nationwide LOLER Inspection and Certification Service carried out by our Team of Professionally Qualified Engineers.
        </p>
        <p class="mb-6 text-xl">
          This is a systematic and detailed examination of the equipment and safety-critical parts, carried out at specified intervals by a competent person who must then complete a written report. This report must contain the information required by LOLER Schedule 1.
        </p>
        
        </div>
        
      </div>
    </div>

    <div id="maintenance" class="mb-16 flex flex-wrap">
      <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
        <div
          class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src="../assets/img/WE3.jpg" class="w-full" alt="Louvre" />
          <a href="#!">
            <div
              class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
            </div>
          </a>
        </div>
      </div>

      <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
        <h3 class="mb-4 text-3xl font-bold">Maintenance, Servicing and Repairs</h3>
        
        <div class="mx-4">
        <p class="mb-6 text-xl">
          We provide 24/7 Maintenance, Servicing and Repairs to a wide range of Underbridge and Hydraulic Access Platforms. Following authorised manufactures repair procedures from welding to overload and final testing,
        </p>
        <p class="mb-6 text-2xl font-bold">
          We also provide: 
        </p>
        <ul class="text-xl">
          <li class="mb-4">Rope inspections and replacement</li>
          <li class="mb-4">Chain inspections and replacement</li>
          <li class="mb-4">Refurbing customer machines</li>
          <li class="mb-4">Remounting old platforms onto new chassis</li>
          <li class="mb-4">Slew bearing (ring) replacement</li>
          
          <li class="mb-4"></li>
          <li class="mb-4"></li>
        </ul>
        
      </div>
        
      </div>

      <div id="welding" class=" mt-16 mb-16 flex flex-wrap lg:flex-row-reverse">
      <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
        <div
          class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src="../assets/img/WE2.jpg" class="w-full" alt="Louvre" />
          <a href="#!">
            <div
              class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
            </div>
          </a>
        </div>
      </div>
      
      <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
        <h3 class="mb-4 text-3xl font-bold">Fabrication</h3>
        
        <div class="mx-4">
        <p class="mb-6 text-xl">
          We have the ability to manufacture cages and other bespoke items such as manufacturers strengthening plates. 
        </p>
        <p class="mb-6 text-xl">
          From MIG, TIG and ARC welding processes, either in our fully equipped workshop based in Luton or onsite with means of generators and welding screens.
        </p>
        
        </div>
        
      </div>
    </div>
    </div>
  </section>
  <!-- Section: Design Block -->
</div>

<!-- Services section -->

</template>

<script>
export default {

}
</script>

<style>

</style>