<template>
<section class="pt-24">
  <!-- Background image -->
  <div
    class="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] bg-[url('../assets/img/UB2.jpg')] h-[500px]">
  </div>

  <div class="w-100 mx-auto px-6 sm:max-w-2xl md:max-w-3xl md:px-12 lg:max-w-5xl xl:max-w-7xl xl:px-32">
    <div class="text-center">
      <div
        class="block mb-12 rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:py-16 md:px-12 mt-[-170px] backdrop-blur-[30px]">
        <h1 class="mt-16 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl8">
          Our Machines
        </h1>
        <!-- <a class="mb-2 inline-block rounded bg-blue-200 px-12 pt-4 pb-3.5 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] md:mr-2 md:mb-0"
          data-te-ripple-init data-te-ripple-color="light" href="#!" role="button">Get started</a>
        <a class="inline-block rounded px-12 pt-4 pb-3.5 text-sm font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 dark:hover:bg-neutral-700 dark:hover:bg-opacity-40"
          data-te-ripple-init data-te-ripple-color="light" href="#!" role="button">Learn more</a> -->
      </div>
    </div>
  </div>
  <!-- Background image -->
</section>

<!-- Container for demo purpose -->
<div class="container mx-auto md:px-6">
<p class="text-xl px-4">We have a wide variety of machines suitable for any job. Below is a selection of our most popular machines. To hire one of these or see what else we have available <a href="/contact" class="text-blue-700 font-semibold underline">Get In Touch</a> </p>
  <!-- Section: Design Block -->
  <section class="mb-32 pt-8 md:pt-32">

    <div class="mb-16 flex flex-wrap">
      <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
        <div
          class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src="../assets/img/man1.jpg" class="w-full" alt="Louvre" />
          <a href="#!">
            <div
              class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
            </div>
          </a>
        </div>
      </div>

      <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
        <h3 class="mb-4 text-4xl font-bold">Manlift Lowrider</h3>
        
        <div class="mx-4">
        <p class="mb-6 text-xl">
          The  Manlift Lowrider is a self propelled, negative reach access platofrm/underbridge unit. 
        </p>
        <p class="mb-6 text-xl">
          The Lowrider is Simple, Safe and Reliable. It has low transport costs and can be set up in minutes. It is self propelled and towable. Perfect for many different jobs.
        </p>
      </div>
        <h2 class="pt-8 md:text-left text-2xl font-bold mb-6 px-4 md:px-0">Reasons to use The Lowrider:</h2>
            <ul class="text-left list-disc list-outside text-lg md:text-xl mx-6">
              <li class="mb-2">Width - 1.44 Metres</li>
              <li class="mb-2">Path Clearance - 1.5 Metres</li>
              <li class="mb-2">Parapet wall clearance - 1.6 Metres</li>
              <li class="mb-2">3.2 Metre lowering depth</li>
              <li class="mb-2">2 Metre under reach</li>
              <li class="mb-2">Low transport height</li>
              <li class="mb-2">225kg safe working load</li>
              <li class="mb-2">Platform Width - 2000mm</li>
              <li class="mb-2">Platform Height - 1100mm</li>
              <li class="mb-2">Platform Depth - 680mm</li>
              <li class="mb-2">LOW Weight</li>
              <li class="mb-2">Reliability - We work to program</li>
              <li class="mb-2">We cover the UK, Ireland and mainland Europe</li>
            </ul>
      </div>
    </div>

    <div class="mb-16 mt-16 flex flex-wrap lg:flex-row-reverse">
      <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
        <div
          class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src="../assets/img/moog2.jpg" class="w-full" alt="Louvre" />
          <a href="#!">
            <div
              class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
            </div>
          </a>
        </div>
      </div>

      <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
        <h3 class="mb-4 text-4xl font-bold">Moog MBI 70 Trailer</h3>
        
        <div class="mx-4">
        <p class="mb-6 text-xl">
          MOOG MBI 70 trailer-mounted platform shares the same specifications as the truck-mounted version. Trailer-mounted under bridge units are towable inspection equipment designed to get engineers and workers safely into places that are otherwise unreachable. Trailer-mounted, self-erecting under bridge inspection units can give personnel safe access to the underside of bridges and can operate without requiring a truck for a base platform.
        </p>
        </div>
        <h2 class="pt-8 md:text-left text-2xl font-bold mb-6 px-4 md:px-0">Reasons to use the Moog MBI 70:</h2>
            <ul class="text-left list-disc list-outside text-lg md:text-xl mx-6">
              <li class="mb-2">Under reach 7 Metres</li>
              <li class="mb-2">Platform Width 1 Metre</li>
              <li class="mb-2">Requires space on bridge 3 Metres</li>
              <li class="mb-2">Max safe working load 400kg</li>
              <li class="mb-2">Extension deck 200kg</li>
              <li class="mb-2">Max lowering depth 5.4 Metres</li>
              <li class="mb-2">180 degree rotation</li>
              
            </ul>
      </div>
    </div>

    <div class="mb-16 flex flex-wrap lg:flex-row">
      <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
        <div
          class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src="../assets/img/mobel160.jpg" class="w-full" alt="Louvre" />
          <a href="#!">
            <div
              class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
            </div>
          </a>
        </div>
      </div>

      <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
        <h3 class="mb-4 text-4xl font-bold">Mobel 60/L</h3>
        
        <div class="mx-4">
        <p class="mb-6 text-xl">
          The 60L underbridge work platform is ideal for inspections as well as repair works of any kind. The spacious platform enables the operators to carry out any sort of heavy-duty repair work underneath bridge decks. 
        </p>
        <p class="mb-6 text-xl">
          The 60L is mounted on a truck chassis 3.5-ton G.V.W. is specifically designed to enable quick and easy access to the underside of the bridge.         </p>
        <p class="mb-6 text-xl">
          Thanks to its high versatility and high performance coupled with low cost the 60L is the ideal machine for both local/national road authorities and private contractors. 
        </p>
        <p class="mb-6 text-xl">
          The 60L machine is, for inspection and maintenance of road bridges, guaranteeing a safe, quick and economic system of access to those structures, where setting up scaffolding is not feasible or is too costly. 
        </p>
        </div>
        <h2 class="pt-8 md:text-left text-2xl font-bold mb-6 px-4 md:px-0">Reasons to use the Mobel 60/L Mobile Platform:</h2>
            <ul class="text-left list-disc list-outside text-lg md:text-xl mx-6">
              <li class="mb-2">Power Tool Outlet on Platform </li>
              <li class="mb-2">180-degree Rotation</li>
              <li class="mb-2">Intercom System between Cab and Platform</li>
              <li class="mb-2">Emergency Stop on Cab and Platform</li>
              <li class="mb-2">Forward and Reverse Trowell on bridge way from platform</li>
              <li class="mb-2">Payload 300kg</li>
              <li class="mb-2">Underbridge Road length 6 metres</li>
              <li class="mb-2">Underbridge down lead 4 metres </li>
              
            </ul>
      </div>
    </div>

    <div class="flex flex-wrap flex-row-reverse">
      <div class="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
        <div
          class="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src="../assets/img/130LS.jpg" class="w-full" alt="Louvre" />
          <a href="#!">
            <div
              class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
            </div>
          </a>
        </div>
      </div>

      <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
        <h3 class="mb-4 text-3xl font-bold">The 130LS</h3>
        
        <div class="mx-4">
        <p class="mb-6 text-xl">
          The 130LS work platform is ideal for inspections as well as repair works of any kind. The spacious platform enables the operators to carry out any sort of heavy-duty repair work underneath bridge decks. 
        </p>
        <p class="mb-6 text-xl">
          The 130LS is mounted on a truck chassis 18,000 Kg. G.V.W and is specifically designed to enable quick and easy access to the underside of the bridge. 
        </p>
        <p class="mb-6 text-xl">
          Thanks to it’s high versatility and high performance couples with it’s low cost, the 130LS is the ideal machine for both local/nation road authorities and private contractors. 
        </p>
        <p class="mb-6 text-xl">
          The 130LS machine is, for inspection and maintenance of road bridges, guaranteeing a safe, quick and economic system of access to those structures, where setting up scaffolding is not feasible or is too costly. 
        </p>
      </div>
        <h2 class="pt-8 md:text-left text-2xl font-bold mb-6 px-4 md:px-0">Reasons to use the 130LS Mobile Platform:</h2>
            <ul class="text-left list-disc list-outside text-lg md:text-xl mx-6">
              <li class="mb-2">Unit mounted onto a standard 2 axles 4x2 Mercedes Benz carrier vehicle</li>
              <li class="mb-2">Road overall dimensions and weights of equipped unit according to international standards</li>
              <li class="mb-2">Stabilisation on rubber covered wheels</li>
              <li class="mb-2">High degree of stability </li>
              <li class="mb-2">Launching time of less than 8 minutes</li>
              <li class="mb-2">Launching/retrieval of equipment without personnel on board the platform </li>
              <li class="mb-2">Unit equipped with hydraulic power drive system to move the vehicle at low speed in forward and reverse on bridge deck with platform deployed in work position </li>
              <li class="mb-2">Two-way intercom system between driver and platform operators</li>
              
            </ul>
      </div>
    </div>

    
  </section>
  <!-- Section: Design Block -->
</div>
<!-- Container for demo purpose -->
</template>

<script>
export default {

}
</script>

<style>

</style>